// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bytedance-js": () => import("./../../../src/pages/Bytedance.js" /* webpackChunkName: "component---src-pages-bytedance-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-me-js": () => import("./../../../src/pages/Me.js" /* webpackChunkName: "component---src-pages-me-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-showcase-js": () => import("./../../../src/pages/Showcase.js" /* webpackChunkName: "component---src-pages-showcase-js" */),
  "component---src-pages-side-projects-js": () => import("./../../../src/pages/SideProjects.js" /* webpackChunkName: "component---src-pages-side-projects-js" */),
  "component---src-pages-smartisan-js": () => import("./../../../src/pages/Smartisan.js" /* webpackChunkName: "component---src-pages-smartisan-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

